// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as React from "react";
import * as Helpers from "../../Helpers.bs.js";

function Posts(Props) {
  var articles = Props.articles;
  return Helpers.array($$Array.map((function (a) {
                    var article = a.node.childMarkdownRemark;
                    return React.createElement("article", {
                                key: article.id
                              }, React.createElement("h1", undefined, Helpers.text(article.frontmatter.title)), React.createElement("span", undefined, Helpers.text(article.frontmatter.date)), React.createElement("div", {
                                    dangerouslySetInnerHTML: {
                                      __html: article.html
                                    }
                                  }));
                  }), articles));
}

var make = Posts;

export {
  make ,
  
}
/* react Not a pure module */
